import React from 'react';
import { Link} from 'react-router-dom';

//images
import shape1 from './../assets/images/home-banner/shape1.png';
import bgimage from './../assets/images/background/bg1.jpg';
import logowhite from './../assets/images/logo-white.png';
import flags from './../assets/images/footer/world-map-with-flags1.png';

function Footer(){
    return(
        <>
            <footer className="site-footer style-1" id="footer">
                <img className="bg-shape1" src={shape1} alt="" />
                <div className="footer-top background-luminosity" style={{backgroundImage: "url("+ bgimage +")"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-12" >
                                <div className="widget widget_about">
                                    <div className="footer-logo logo-white">
                                        <Link to={"/"}><img src={logowhite} alt="" /></Link>
                                    </div>
                                    <p>Your All-in-One Trading Bot Platform. Empower your trading strategies with a comprehensive solution offering advanced tools, real-time analytics, and seamless integration for optimal performance in the dynamic world of trading</p>
                                    <div className="dz-social-icon transparent space-10">
                                        <ul>
                                            <li><a target="_blank" className="fab fa-telegram" href="https://t.me/anna_tradeblocks_ai"></a></li>{" "}
                                            <li><a target="_blank" className="fab fa-telegram" href="https://t.me/chain_chakra"></a></li>{" "}
                                            {/* <li><a target="_blank" className="fab fa-instagram" href="https://www.instagram.com/"></a></li>{" "} */}
                                            <li><a target="_blank" className="fab fa-twitter" href="https://twitter.com/TradeBlocksAI"></a></li>{" "}
                                            {/* <li><a target="_blank" className="fab fa-github" href="https://github.com/Trade-Blocks-AI"></a></li>{" "} */}
                                            {/* <li><a target="_blank" className="fab fa-youtube" href="https://youtube.com/"></a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6" >
                                <div className="widget widget_services">
                                    <h4 className="widget-title">Other Links</h4>
                                    <ul>
                              
                                        <li><Link to={"/pricing"}>Pricing Plan</Link></li>
                                       
                                        <li><Link to={"/contact-us"}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6" >
                                <div className="widget recent-posts-entry">
                                    <h4 className="widget-title">Blog Posts</h4>
                                    <div className="widget-post-bx">
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><Link to={"#"}>What is cryptocurrency and how does it work.</Link></h6>
                                                <span className="post-date"> JUNE 18, 2023</span>
                                            </div>
                                        </div>
                                        <div className="post-separator"></div>
                                        <div className="widget-post clearfix">
                                            <div className="dz-info">
                                                <h6 className="title"><Link to={"#"}>A cryptocurrency is a digital currency.</Link></h6>
                                                <span className="post-date"> AUGUST 22, 2023</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-12" >
                                <div className="widget widget_locations">
                                    <h4 className="widget-title">Locations</h4>
                                    <div className="clearfix">
                                        <h6 className="title">UAE , Dubai</h6>
                                        <p>Box No. 47775,Dubai,Emirates</p>
                                        <h6 className="title">Singapore</h6>
                                        <p>Level 49-50, One Raffles Quay North Tower, 048583</p>
                                        {/* <h6 className="title">Pakistan , Lahore</h6>
                                        <p>AlHafeez Executive , 515 , Gulberg</p> */}

                                      
                                        <img src={flags} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer-bottom text-center">
                    <div className="container">
                        <span className="copyright-text">Copyright © 2023 <a href="https://tradeblocks.ai" target="_blank">Trade Blocks</a>. All rights reserved.</span>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;