import React from 'react';
import {Link} from 'react-router-dom';

//Images
import Icon9 from './../../assets/images/icons/bot.png';
import Icon10 from './../../assets/images/icons/market-analysis.png';
import Icon11 from './../../assets/images/icons/coin.png';
import Icon12 from './../../assets/images/icons/idea.png';
import Icon13 from './../../assets/images/icons/productivity.png';
import pic1 from './../../assets/images/about/pic1.jpg';
import support1 from './../../assets/images/icons/support1.png';

const cardData = [
    {image: Icon9, title:'AI Market Making',description:"AI-Powered Market Making: Boost Bot Performance. Leverage AI to enhance your trading bot's market making abilities for improved liquidity and profitability"},
    {image: Icon10, title:'Exploring Market Valuation',description:"Unveiling the real value of assets. This is how markets establish fair prices, considering factors like supply, demand, and more"},
    {image: Icon11, title:'Arbitrage Protection',description:"This strategy safeguards your investments by identifying and preventing arbitrage opportunities"},
    {image: Icon12, title:'Tailored Trading Solutions',description:"These tailored approaches are designed to meet your unique trading goals and preferences, ensuring a personalized and efficient trading experience"},
    {image: Icon13, title:'Real-Time Precision',description:"With lightning-fast trades, it optimizes liquidity and maximizes profits in real-time, enhancing your trading experience"},
];

function OneStop(){
    return(
        <>
            {cardData.map((item, ind)=>(
                <div className="col-xl-4 col-md-6 m-b60" key={ind}>
                    <div className="icon-bx-wraper style-3 text-center">
                        <div className="icon-media">
                            <img style={{width:"60%"}} src={item.image} alt="" />
                        </div>
                        <div className="icon-content">
                            <h4 className="title">{item.title}</h4>
                            <p className="m-b0">{item.description}</p>
                        </div>
                    </div>
                </div>
            ))}
            <div className="col-xl-4 col-md-6 m-b60">
                <div className="icon-bx-wraper style-4" style={{backgroundColor:'black'}}>
                    <div className="inner-content">
                        <div className="icon-media m-b30">
                            <img src={support1} alt="" />
                            <p className="m-b0">Want to schedule a Demo ? </p>
                        </div>
                        <div className="icon-content">
                            <Link to={"/contact-us"} className="btn btn-primary">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OneStop;