import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

//components
import BannerCard from './../components/Home/BannerCard';
import OneStop from './../components/Home/OneStop';
import RecentNews from './../components/Home/RecentNews';

//images
import baner1 from './../assets/images/home-banner/img1.png';
import baner2 from './../assets/images/home-banner/img2.png';
import Shape1 from './../assets/images/home-banner/shape1.png';
import Shape3 from './../assets/images/home-banner/shape3.png';
import wallet from './../assets/images/icons/wallet.svg';
import friend from './../assets/images/icons/friend.svg';

import coin1 from './../assets/images/coins/coin1.png';
import coin3 from './../assets/images/coins/coin3.png';
import coin4 from './../assets/images/coins/coin4.png';

import bloglg from './../assets/images/blog/blog-ig.png';
import avatar3 from './../assets/images/avatar/avatar3.jpg';


const trustBlog = [
	{ image: wallet, title: 'Buy Cryptocurrency with cash' },
	{ image: friend, title: 'Cryptocurrency Consultancy' },
];

function Home() {
	const nav = useNavigate();
	const formDetails = (e) => {
		e.preventDefault();
		nav("/contact-us");
	};
	const [selecttext, setSelectText] = useState([coin4, 'Bitcoin']);
	return (
		<>
			<div className="page-content">
				<div className="main-bnr style-1">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 text-center">
								<h1 className="" >Unlock Your Trading <br /> Potential with <span className="text-primary">TradeBlocks</span> </h1>
								<div className="text" style={{ color: "white" }} > <i>Where AI Creates Profits with Precision</i></div>
								<Link to={"/contact-us"} className="btn space-lg btn-gradient btn-shadow btn-primary " >Contact Us</Link>
								<ul className="image-before">
									<li className="left-img"><img src={baner1} alt="" /></li>
									<li className="right-img"><img src={baner2} alt="" /></li>
								</ul>
							</div>
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
					<img className="bg-shape2" src={Shape1} alt="" />
					<img className="bg-shape3" src={Shape3} alt="" />
					<img className="bg-shape4" src={Shape3} alt="" />
				</div>
				<div className="clearfix bg-primary-light">
					<div className="container">
						<div className="currancy-wrapper">
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={0}>
									<BannerCard coinid="bitcoin" />
								</div>
								<div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={1}>
									<BannerCard coinid="ethereum" />
								</div>
								<div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s" key={2}>
									<BannerCard coinid="binancecoin" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<section className="clearfix section-wrapper1 bg-primary-light">
					<div className="container">
						<div className="content-inner-1">
							<div className="section-head text-center">
								<h2 className="title">Why Trust Us?</h2>

							</div>
							<br />
							<br />
							<div className="row">
								<div className="col-lg-6 col-md-6 m-b30 ">
									<h3 className="" >Trust comes from experience. Many of the pleased <span className="text-primary">customers</span> may function as a guide for you. </h3>
								</div>


								<div className="col-lg-6 col-md-6 m-b30 ">
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/25269.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/27922.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/20433.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/27434.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/20396.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/7548.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/23246.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/26103.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/3640.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/8831.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/coins/64x64/7412.png" /></span>

								</div>
							</div>
						</div>
					</div>

					<img className="bg-shape1" src={Shape1} alt="" />
				</section>

				<section className="clearfix section-wrapper1 bg-primary-light">
					<div className="container">
						<div className="content-inner-1">
							<div className="section-head text-center">
								<h2 className="title">Exchanges we support</h2>
								
							</div>

							<br/>
							
							<div className="row">
								


								<div className="col-lg-6 col-md-6 m-b30 ">
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/270.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/89.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/24.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/521.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/403.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/351.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/477.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/544.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/407.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/380.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/587.png" /></span>

									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/350.png" /></span>
									<span ><img style={{ width: "55px" }} className='m-2' src="https://s2.coinmarketcap.com/static/img/exchanges/64x64/1149.png" /></span>


								</div>

								<div className="col-lg-6 col-md-6 m-b30 ">
									<h3 className="" >Our Market Making bot supports <br/><span className="text-primary">50+ exchanges</span> including Top-20 </h3>
								</div>
							</div>
						</div>
					</div>

					<img className="bg-shape1" src={Shape1} alt="" />
				</section>

				<section className="content-inner bg-light icon-section section-wrapper2">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="title">One stop solution for <br /><span className="text-primary">Crypto Projects </span> and <span className="text-primary">Traders</span></h2>
						</div>
						<div className="row sp60">
							<OneStop />
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
				</section>
				{/* <section className="content-inner bg-white blog-wrapper">
					<img className="bg-shape1" src={Shape1} alt="" />

					<div className="container">
						<div className="row">
							<div className="col-xl-7 col-lg-12">
								<div className="section-head " >
									<h6 className="sub-title text-primary">FROM OUR BLOG</h6>
									<h2 className="title">Recent News &amp; Updates</h2>
								</div>
								<RecentNews />
							</div>
							<div className="col-xl-5 col-lg-12 m-b30 " >
								<div className="dz-card style-2" style={{ backgroundImage: "url(" + bloglg + ")" }}>
									<div className="dz-category">
										<ul className="dz-badge-list">
											<li><Link to={"#"} className="dz-badge">14 Fan 2022</Link></li>
										</ul>
									</div>
									<div className="dz-info">
										<h2 className="dz-title"><Link to={"#"} className="text-white">Directly support individuals Crypto</Link></h2>
										<div className="dz-meta">
											<ul>
												<li className="post-author">
													<Link to={"#"}>
														<img src={avatar3} alt="" className="me-2" />
														<span>By Noare</span>
													</Link>
												</li>
												<li className="post-date"><Link to={"#"}> 12 May 2022</Link></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
			</div>
		</>
	)
}
export default Home;