import React, { useEffect, useState } from 'react';
import axios from 'axios';




function BannerCard(data){
    
    const [cardData, setCoinData] = useState({image: null, title:'Bitcoin', subtitle:'BTC', price:'16,048.40', percent:'-1.26'});

    const getBTCPrice = ()=>{
        const apiUrl = 'https://api.coingecko.com/api/v3/coins/'+data.coinid;
        const params =  {
            localization: false,
            tickers: false,
            market_data: true,
            community_data: false,
            developer_data: false,
            sparkline: false,
          }; axios
          .get(apiUrl, { params })
          .then((response) => {
            const coinResponse = response.data;
            let tempPrice = {}
            tempPrice.title = coinResponse.name;
            tempPrice.subtitle = coinResponse.symbol.toUpperCase();
            tempPrice.image = coinResponse.image.large
            tempPrice.price = coinResponse.market_data.current_price.usd;
            tempPrice.percent = (coinResponse.market_data.price_change_24h/100).toPrecision(3)
            setCoinData(tempPrice);
          })
          .catch((error) => {
            console.error('Error fetching Bitcoin price:', error);
          });
    }
 
    useEffect(() => {
        // Define the CoinGecko API URL
        getBTCPrice();
        //getETHPrice();
        //getBNBPrice()
      },[])
    return(
        <>
          
               
                    <div className="icon-bx-wraper style-1 box-hover">
                        <div className="icon-media">
                            <img src={cardData.image} alt="" />
                            <div className="icon-info">
                                <h5 className="title">{cardData.title}</h5>
                                <span>{cardData.subtitle}</span>
                            </div>
                        </div>
                        <div className="icon-content">
                            <ul className="price">
                                <li>
                                    <h6 className="mb-0 amount">${cardData.price}</h6>
                                    <span className= {`percentage ${cardData.percent>=0 ? "text-green" : "text-red"}`}>{cardData.percent}%</span>
                                </li>
                                <li>
                                    <span>Latest price</span>
                                    <span>24h change</span>
                                </li>
                            </ul>
                        </div>
                    </div>
            
       
        </>
    )
}

export default BannerCard;